import React, { useState } from "react";
import { Formik } from "formik";

import Requirements from "./Requirements";

const FancyRegistration = props => {
  const [long, longEnough] = useState(false);
  const [number, hasNumber] = useState(false);

  return (
    <Formik
      initialValues={{ email: "", name: "" }}
      // validate={values => {
      //   values.name.length < 3? longEnough(false) : longEnough(true);
      //   !/\d/.test(values.password) ? hasNumber(false) : hasNumber(true);
      // }}
      onSubmit={(values, { resetForm, setErrors, setSubmitting }) => {
        let errors = {};
        const api = 'https://cmrsutbg9i.execute-api.us-east-2.amazonaws.com/default/formprocess'
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            email: values.email,
            name: values.name
          })
        }
        fetch(api, requestOptions)
          .then((response) => {
            console.log(response)
          alert('Your registration was successful. Check your email for further instructions.')
          })
          .catch((error) => {
            console.error('something went wrong: ', error)
            alert('Something went wrong. Please try again.')
          })

        console.log(values)

        // if (!long || !number)
        //   errors.password =
        //     "Your password doesn't currently meet the requirements";

        // // If the errors objcet is empty then we've successfully met all the requirements
        // if (
        //   Object.entries(errors).length === 0 &&
        //   errors.constructor === Object
        // ) {
        //   alert(`Great, we've created an account for ${values.email}`);
        //   resetForm(); // Reset form for the example
        // } else {
        //   setErrors(errors);
        // }

        setSubmitting(false);
      }}
    >
      {({ errors, values, handleChange, handleSubmit, isSubmitting }) => (
        <>
          <h1>Create Your Account</h1>
          <form onSubmit={handleSubmit}>
         
            <label htmlFor="name">What's your name?</label>
            <input
              autoFocus
              id="name"
              type="name"
              name="name"
              onChange={handleChange}
              required
              value={values.name}
            />
            <label htmlFor="email">
              What's your email?
            </label>
            <input
              id="email"
              type="email"
              name="email"
              onChange={handleChange}
              required
              value={values.email}
            />
          
            
            <button
              type="submit"
              value="Create account"

              disabled={isSubmitting}
            >
              Create account
            </button>
          </form>
        </>
      )}
    </Formik>
  );
};

export default FancyRegistration;
