import React from 'react';
import './App.css';
import "normalize.css";
import "./styles.css";
import FancyRegistration from "./FancyRegistration";
import StyledWrapper from "./Styles";

// import RegistrationForm from './components/registration/RegistrationForm'

function App() {
  return (
    <div className="App">
    <StyledWrapper>
      <FancyRegistration />
    </StyledWrapper>
    </div>
  );
}

export default App;
